import { publicRuntimeConfig } from '@/app/util/env'
import { IGif } from '@giphy/js-types'

const { taggerApiKey, serviceGatewayUrl } = publicRuntimeConfig
const apiUrl = `${serviceGatewayUrl}v1/trendingscheduler`

type TrendingScheduleGif = {
    contentType: string
    createDatetime: string
    id: number
    retries: number
    trendDatetime: string
    userId: number
}

export const getTrendingScheduleGif = async (gifId: string, userToken: string) => {
    const f = await fetch(`${apiUrl}/schedule/${gifId}?api_key=${taggerApiKey}&access_token=${userToken}`)
    const results = await f.json()

    if (results.error) {
        throw results.error
    }

    return results as TrendingScheduleGif
}

export const scheduleGifsToTrend = async (gifs: IGif[], date: Date, userToken: string) => {
    const timestamp = date.getTime()
    try {
        const f = await fetch(
            `${apiUrl}/schedule/?timestamp=${timestamp}&api_key=${taggerApiKey}&access_token=${userToken}`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    gifs: gifs.map((gif) => ({ id: gif.id, is_sticker: gif.is_sticker, type: gif.type })),
                }),
            }
        )
        return f.json()
    } catch (error) {
        console.error(error, 'Backend error trying to schedule GIFs to trend.')
    }
}
